<template>
	<div class="container">
		<component :is="component" :class="urlValue == '' ? 'component-container':'component-container-full'"></component>
		<van-tabbar v-if="urlValue == ''" v-model="component">
			<van-tabbar-item v-for="(item, key) in navs" :key="key" :name="item.component" icon-prefix="icon" :icon="item.icon">{{ item.name }}
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	import Login from '../login/index.vue'
	import Map from './map.vue'
	import Video from './video.vue'
	import Kaoqin from './kaoqin.vue'
	import { getQueryString } from '@/utils/utils'
	export default {
		components: {
			Login,
			Map,
			Video,
			Kaoqin,
		},
		data() {
			return {
				component: 'Map',
				navs: [{
					icon: 'map',
					name: '地图',
					component: 'Map'
				}, {
					icon: 'kaoqin',
					name: '考勤',
					component: 'Kaoqin'
				}, {
					icon: 'camera',
					name: '摄像头',
					component: 'Video'
				}],
				urlValue:'',
			}
		},
		created() {
			var value = getQueryString('component')
			console.log(value)
			if(value != null && value != '') {
				this.component = value
				this.urlValue = value
			}
		},
		methods: {
		}
	}
</script>
<style>
	.container {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	
	.component-container {
		width: 100%;
		height: calc(100% - 50px);
		overflow: auto;
	}
	.component-container-full {
		width: 100%;
		height: 100%;
		overflow: auto;
	}
</style>