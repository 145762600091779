import request from '@/utils/request'
import axios from 'axios'

export function waterSiteList() {
	return request({
		url: 'waterLevel/getWaterLevel',
		method: 'get',
	})
}

export function waterLevelList(data) {
	return request({
		url: 'screen/riverlevel',
		method: 'post',
		params: data
	})
}

export function getUpperReachesWatersites(siteName) {
	return request({
		url: 'screen/getUpperReachesWatersites',
		method: 'get',
		params: {
			siteName: siteName
		}
	})
}

export function getUserRiverCollections(siteName) {
	return request({
		url: 'app/riverCollection/getUserRiverCollections',
		method: 'get',
		params: {
			siteName: siteName
		}
	})
}

export function saveUserRiverCollection(siteName) {
	return request({
		url: 'app/riverCollection/saveUserRiverCollection',
		method: 'get',
		params: {
			siteName: siteName
		}
	})
}

export function getWaterSites(isShowAll) {
	return request({
		url: '/screen/water/sites',
		method: 'get',
		params: {
			isShowAll: isShowAll
		}
	})
}

export function getRiverLevel(startTime, endTime, siteName) {
	return axios({
		url: 'http://112.126.66.76:10007/yangtze-river-collect/river/level/query?startTime=' + startTime + '&endTime=' + endTime + '&siteName=' + siteName,
		method: 'get'
	})
}