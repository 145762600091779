<template>
	<div class="map-container">
		<van-popup v-model="showWatersitePopup" v-if="showWatersitePopup" :position="urlValue = '' ? 'bottom':'bottom bottom-full'" round :overlay="false">
			<div class="detail-header-box">
				<div class="header-left-icon"><span class="icon water-droplet"></span></div>
				<div class="header-name">{{watersiteData.waterSite}}</div>
				<div v-if="userRiverCollection.indexOf(watersiteData.waterSite) == -1" class="header-right-icon" @click="saveCollectionData"><span class="icon uncollection"></span></div>
				<div v-else class="header-right-icon" @click="saveCollectionData"><span class="icon collection"></span></div>
				<div class="header-more" @click="showWatersiteDetail = true">更多></div>
			</div>
			<div class="detail-content-box">
				<div class="content-box">
					<div class="content-title">今日：</div>
					<div class="content-value">{{currentdayWatersite}}米</div>
				</div>
				<div class="content-box">
					<div class="content-title">昨日：</div>
					<div class="content-value">{{yesterdayWatersite}}米</div>
				</div>
				<div class="content-box">
					<div class="content-title">去年：</div>
					<div class="content-value">{{lastyeardayWatersite}}米</div>
				</div>
			</div>
			<div id="echart-box" ref="echart" class="detail-profile-box">
			</div>
		</van-popup>
		<WatersiteDetail v-if="showWatersiteDetail" class="detail-page" :selectData="watersiteData" :pCurrentdayWatersite="currentdayWatersite" :pSiteNameList="siteNameList" :pLevelList="levelList" @closeDetail="closeDetail" @showHistory="showHistory">
		</WatersiteDetail>
		<WatersiteHistory v-if="showWatersiteHistory" class="detail-page" :selectData="watersiteData" @closeDetail="closeDetail">
		</WatersiteHistory>
	</div>
</template>

<script>
	import WatersiteDetail from '@/components/Map/watersite/watersite-detail.vue'
	import WatersiteHistory from '@/components/Map/watersite/watersite-history.vue'
	import { getWaterLevelHistory, getUpperReachesData, getUserRiverCollectionData, saveUserRiverCollectionData } from '@/views/index/map/watersite-layer'
	import { getQueryString } from '@/utils/utils'
	import * as echarts from 'echarts';
	export default {
		name: 'WatersitePopup',
		components: {
			WatersiteDetail,
			WatersiteHistory
		},
		props: {
			selectData: Object
		},
		data() {
			return {
				urlValue: '',
				showWatersitePopup: true,
				showWatersiteDetail: false,
				showWatersiteHistory: false,
				watersiteData: {},
				currentdayWatersite: 0,
				yesterdayWatersite: 0,
				lastyeardayWatersite: 0,
				siteNameList: [],
				levelList: [],
				userRiverCollection: '',
				userRiverCollectionList: [],
				upperReachesOption: {
					grid: {
						x: "0%", //x 偏移量
						y: "25%", // y 偏移量
						width: "100%", // 宽度
						height: "70%" // 高度
					},
					xAxis: {
						type: 'category',
						position: 'top',
						data: [],
						splitLine: {
							show: false
						},
						axisLine: { //x轴坐标轴
							show: false
						},
						axisLabel: {
							show: true
						},
						axisTick: { //x轴刻度线
							show: false,
							alignWithLabel: false,
						}

					},
					yAxis: {
						type: 'value',
						max: 300,
						splitLine: {
							show: false
						},
						axisLine: { //x轴坐标轴
							show: false
						},
						axisLabel: {
							show: false
						},
						axisTick: { //x轴刻度线
							show: false,
							alignWithLabel: false,
						}
					},
					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true
								}
							}
						},
						data: [],
						type: 'line'
					}]
				},
			}
		},
		watch: {
			selectData(nValue, oValue) {
				// do something
				console.log(nValue, oValue)
				this.initData();
			}
		},
		created() {
			var component = getQueryString('component')
			//console.log('component' + component)
			if(component != null && component != '') {
				this.component = component
				this.urlValue = component
			}
			this.initData()
		},
		mounted() {},
		methods: {
			initData() {
				if(!this.selectData.waterSite) {
					this.watersiteData = {
						"waterSite": "羊角堡",
						"coordinates": "[107.078617,29.817217]"
					}
				} else {
					this.watersiteData = this.selectData
				}
				getWaterLevelHistory(this.watersiteData, this) //获取今天昨天去年历史水位
				getUpperReachesData(this.watersiteData, this) //获取上游
				getUserRiverCollectionData(this.watersiteData, this) //获取收藏
			},
			saveCollectionData() {
				saveUserRiverCollectionData(this.watersiteData, this)
			},
			initUpperReachesChart() {
				let echartBox = document.getElementById('echart-box')
				this.upperReachesOption.xAxis.data = this.siteNameList
				this.upperReachesOption.series[0].data = this.levelList
				echarts.init(echartBox).setOption(this.upperReachesOption)
			},
			closeDetail(type) {
				if(type == 'detail') {
					this.showWatersiteDetail = false

				} else {
					this.showWatersiteDetail = true
				}
				this.showWatersiteHistory = false
				getUserRiverCollectionData(this.watersiteData, this) //获取收藏
			},
			showHistory() {
				this.showWatersiteDetail = false
				this.showWatersiteHistory = true
			}
		}
	}
</script>

<style scoped>
	.van-popup--bottom {
		bottom: 50px;
		max-height: 80%;
	}
	
	.van-popup--bottom-full {
		bottom: 50px;
		max-height: 80%;
	}
	
	.detail-header-box {
		padding: 10px 10px 0px 10px;
		height: 40px;
	}
	
	.detail-header-box .header-left-icon,
	.detail-header-box .header-right-icon {
		font-family: iconfont;
		color: #2770d7;
		font-weight: 700;
		font-size: 0.5rem;
		height: 1rem;
		/*line-height: 0.7rem;
		padding-left: 0.2rem;*/
		width: 30px;
		float: left;
	}
	
	.detail-header-box .header-name {
		font-size: .439024rem;
		color: #45474f;
		float: left;
		margin-left: 5px;
		/*font-weight: 700;*/
	}
	
	.detail-header-box .header-more {
		float: right;
		font-size: 12px;
		color: #969799;
		margin-top: 1px;
		padding: 3px 8px 2px 8px;
	}
	
	.detail-header-box .position {
		margin-left: 10px;
		margin-top: 1px;
		font-size: 10px;
		background-color: #80D3F8;
		color: white;
		padding: 3px 5px 3px 5px;
		border-radius: 6px;
		float: left;
	}
	
	.detail-header-box .icon {
		background-size: cover;
		background-position: center;
		display: inline-block;
	}
	
	.water-droplet {
		width: 27px;
		padding-top: 24px;
		background: url("~@/assets/images/map/watersite/water-droplet.png") no-repeat;
	}
	
	.uncollection {
		width: 40px;
		padding-top: 22px;
		background: url("~@/assets/images/map/watersite/uncollection.png") no-repeat;
	}
	
	.collection {
		width: 40px;
		padding-top: 22px;
		background: url("~@/assets/images/map/watersite/collection.png") no-repeat;
	}
	
	.detail-content-box {
		height: 35px;
		line-height: 35px;
		margin-left: 15px;
		margin-right: 15px;
		background-color: #F6F8FA;
		padding-left: 15px;
		padding-right: 15px;
		position: absolute;
		width: calc(100% - 60px);
	}
	
	.detail-content-box .content-box {
		float: left;
		width: 30%;
	}
	
	.detail-content-box .content-box .content-title {
		float: left;
		width: 45%;
		color: #797979;
		font-weight: 800;
	}
	
	.detail-content-box .content-box .content-value {
		float: left;
		width: 55%;
		text-align: right;
		color: #797979;
		margin-top: 1px;
		font-weight: 600;
	}
	
	.detail-content-box .content-box:nth-of-type(n+2) {
		margin-left: 5%;
		color: #797979;
	}
	
	.detail-profile-box {
		height: 150px;
		margin: 50px 15px 15px 15px;
		border-width: medium;
		border-style: solid;
		border-color: #DDDDDD;
	}
	
	.watersite-details-page {
		width: 100%;
		/* height: 100%; */
		/* overflow: hidden; */
		z-index: 99999;
		position: fixed;
		overflow: scroll!important;
	}
</style>
<style>

</style>