<template>
	<div class="map-container">
		<ShipDetail v-if="isShowDetail.shipDetail" class="detail-page" :selectData="selectData" @closeDetail="isShowDetail.shipDetail = false"></ShipDetail>
		<WatersitePopup v-if="showWatersitePopup" :selectData="selectData"></WatersitePopup>
		<ProjectDetails v-if="projectDetailsVisible" :project="selectedProject" @close="closeProjectDetails" />
		<div class="ship-search">
			<el-select
			v-model="searchOption.value"
			filterable
			remote
			clearable
			reserve-keyword
			placeholder="请输入船名、MMSI、IMO或呼号"
			:remote-method="remoteMethod"
			:loading="searchOption.loading"
			@change="shipSearchSelect">
			<el-option-group 
				v-for="group in searchOption.options" 
				:key="group.label" 
				:label="group.label">
				<el-option 
				v-for="item in group.options" 
				:key="item.value" 
				:label="item.label" 
				:value="item.value" />
			</el-option-group>
			</el-select>
        </div>
		<div ref="map" id="map"></div>
		<div class="overlay-container">
			<div class="toolbox toolbox-layer icon-layer" @click="showLayerPopup = true"></div>
			<div class="toolbox toolbox-clear icon-qingchu" v-if="showClearTrack" @click="clearHistoryTrack"></div>
			<div class="toolbox toolbox-zoom-in icon-plus" @click="setZoom(1)"></div>
			<div class="toolbox toolbox-zoom-out icon-minus" @click="setZoom(-1)"></div>
			<div class="toolbox toolbox-center icon-center" @click="setCenter"></div>
			<div class="toolbox toolbar-toggle icon-alarm" @click="showToolbar = true">
				<span v-if="unreadCount > 0" class="unread-count">{{ unreadCount }}</span>
				<span>通告</span>
			</div>
		</div>
		<h2></h2>
		<van-popup v-model="showLayerPopup" position="right" :style="{ width: '3.5rem', height:'100%' }">
			<div class="layer-popup-title">图层设置</div>
			<ul class="layer-switch">
				<li><span class="icon-weixingtu"></span><label>卫星图</label>
					<van-switch v-model="tiandituVisible" size="0.439024rem" />
				</li>
				<li><span class="icon-river"></span><label>航道</label>
					<van-switch v-model="channelVisible" size="0.439024rem" />
				</li>
				<li><span class="icon-marker"></span><label>航标</label>
					<van-switch v-model="markerVisible" size="0.439024rem" />
				</li>
				<li><span class="icon-anchor"></span><label>锚地</label>
					<van-switch v-model="anchorVisible" size="0.439024rem" />
				</li>
				<li><span class="icon-ais"></span><label>AIS</label>
					<van-switch v-model="aisVisible" size="0.439024rem" />
				</li>
				<li><span class="icon-dxf"></span><label>工程</label>
					<van-switch v-model="dxfVisible" size="0.439024rem" />
				</li>
				<li><span class="icon-camera"></span><label>视频</label>
					<van-switch v-model="cameraVisible" size="0.439024rem" />
				</li>
				<li><span class="icon-search"></span><label>标注</label>
					<van-switch v-model="areaVisible" size="0.439024rem" />
				</li>
				<li><span class="icon-kaoqin"></span><label>船舶</label>
					<van-switch v-model="locatorVisible" size="0.439024rem" />
				</li>
				<li><span class="icon-minus"></span><label>围栏</label>
					<van-switch v-model="enclosureVisible" size="0.439024rem" />
				</li>
				<!--<li><span class="icon-dxf"></span><label>水位</label>
					<van-switch v-model="watersiteVisible" size="0.439024rem" />
				</li>-->
				<li><span class="icon-layer"></span><label>报警</label>
					<van-switch v-model="alarmVisible" size="0.439024rem" />
				</li>
			</ul>
		</van-popup>
		<van-popup v-model="showShipPopup" v-if="showShipPopup" :position="urlValue = '' ? 'bottom':'bottom bottom-full'" round :overlay="false" @closed="closePopup">
			<div class="detail-header" :style="hiddenContent ? 'height:60px;': ''">
				<div class="header-name">{{selectData.name || selectData.shipName || selectData.mmsi || (selectData.mmsis && selectData.mmsis[0])}}</div>
				<div class="position" v-if="selectData.tenantId">{{selectData.deviceType == '1' ? 'AIS' : 'BDS'}}</div>
				<div class="ship-type" v-if="selectData.isPresent == 1">工 </div>
				<div class="more" v-if="hiddenContent == false && selectData.tenantId" @click="openDetail('shipDetail')">更多> </div>
				<div class="more" v-if="hiddenContent" @click="hiddenContent = false">展开</div>
			</div>
			<div class="ship-detail-list" v-if="!hiddenContent">
				<template>
					<div class="ship-list-item">
						<div class="ship-item2">
							<span class="item-label">船舶类型:</span>
							<span class="item-value">{{selectData.shipTypeName}}</span>
						</div>
						<div class="ship-item3">
							<span class="item-label">MMSI:</span>
							<span class="item-value">{{selectData.mmsi ||(selectData.mmsis && selectData.mmsis[0])}}</span>
						</div>
					</div>
					<div class="ship-list-item">
						<div class="ship-item2">
							<span class="item-label">总长:</span>
							<span class="item-value">{{selectData.shipLength / 10 || '-' }}米</span>
						</div>
						<div class="ship-item3">
							<span class="item-label">船长:</span>
							<span class="item-value">{{selectData.shipLen || '-' }}米</span>
						</div>
					</div>
					<div class="ship-list-item">
						<div class="ship-item2">
							<span class="item-label">型深:</span>
							<span class="item-value">{{selectData.shapeDepth || '-' }}米</span>
						</div>
						<div class="ship-item3">
							<span class="item-label">船宽:</span>
							<span class="item-value">{{selectData.breadth / 10 || '-' }}米</span>
						</div>
					</div>
					<div class="ship-list-item">
						<div class="ship-item2">
							<span class="item-label">经度:</span>
							<span class="item-value">{{selectData.hdms.x || '-'}}</span>
						</div>
						<div class="ship-item3">
							<span class="item-label">纬度:</span>
							<span class="item-value">{{selectData.hdms.y || '-'}}</span>
						</div>
					</div>
					<div class="ship-list-item">
						<div class="ship-item2">
							<span class="item-label">航向:</span>
							<span class="item-value">{{selectData.course}}</span>
						</div>
						<div class="ship-item3">
							<span class="item-label">速度:</span>
							<span class="item-value">{{selectData.speed}}</span>
						</div>
					</div>
					<div class="ship-list-item">
						<div class="ship-item2">
							<span class="item-label">报位时间:</span>
							<span class="item-value2">{{dateFormat('yyyy-MM-dd HH:mm:ss',new Date((selectData.postime || 0) * 1000))}}</span>
						</div>
					</div>
					<div class="ship-list-item">
						<div class="ship-item2">
						<span style="padding: 0px;vertical-align: sub;">
							<span @click="showStartTimer = true">{{ dateFormat('yyyy-MM-dd HH:mm:ss', historyTime[0]) }}</span> 至
							<span @click="showEndTimer = true">{{ dateFormat('yyyy-MM-dd HH:mm:ss', historyTime[1]) }}</span>
						</span>
						<van-button type="primary" size="normal" style="margin-left: 2%" @click="queryHistory">查询轨迹</van-button>
						</div>
					</div>
					<br />
				</template>
			</div>
		</van-popup>
		<van-popup v-model="showStartTimer" :position="urlValue = '' ? 'bottom':'bottom bottom-full'" round :overlay="false">
			<van-datetime-picker
				v-model="historyTime[0]"
				type="datetime"
				title="选择开始时间"
				@confirm="showStartTimer = false"
			/>
		</van-popup>
		<van-popup v-model="showEndTimer" :position="urlValue = '' ? 'bottom':'bottom bottom-full'" round :overlay="false">
			<van-datetime-picker
				v-model="historyTime[1]"
				type="datetime"
				title="选择开始时间"
				@confirm="showEndTimer = false"
			/>
		</van-popup>
		<van-popup 
			v-model="showAlarmPopup" 
			v-if="showAlarmPopup" 
			:position="urlValue === '' ? 'bottom' : 'bottom bottom-full'" 
			round 
			:overlay="false" 
			:closeable="true" 
			@closed="closePopup"
			:style="{ height: '20%' }" 
			>
			<div class="detail-header" :style="hiddenContent ? 'height:80px;': ''">
				<div class="header-name">{{selectData.name}}</div>
				<div class="header-content">{{selectData.shipName || selectData.mmsi || (selectData.mmsis && selectData.mmsis[0])}}</div>
				<div class="header-ms">{{selectData.alarmTime}}</div>
			</div>
		</van-popup>
		<!--<van-popup v-model="showWatersitePopup" v-if="showWatersitePopup" :position="urlValue = '' ? 'bottom':'bottom bottom-full'" round :overlay="false" :closeable="true" @closed="closePopup">
			<div class="detail-header">
				<div class="header-name">{{selectData.waterSite}}</div>
				<div class="header-ms"></div>
			</div>
			<div class="detail-list" v-if="showWatersiteDetail">
				<div class="list-item" style="height: 10px;">
				</div>
				<div class="list-item">
					<div class="list-label">时间</div>
					<div class="list-value">水位值(m)</div>
				</div>
				<template>
					<div v-for="item in selectDetail" :key="item.time">
						<div class="list-item">
							<div class="list-label">{{item.time}}</div>
							<div class="list-value">{{item.level}}</div>
						</div>
					</div>
				</template>
			</div>
			<div class="detail-locate-block" v-if="!showWatersiteDetail">
				<van-button type="info" block round size="small" @click="getWatersiteDetail()">详情</van-button>
			</div>
		</van-popup>-->

		<van-popup v-model="showToolbar" position="bottom" :style="{ height: 'auto' }">
			<div class="toolbar-container unselectable">
				<div class="toolbar-header">
				<span>通告</span>
				<van-icon name="cross" @click="showToolbar = false" />
				</div>
				<div class="toolbar-content">
				<div class="toolbar-item" @click="getNoticeList('航行航道通告')">
					<i class="iconfont">&#xe63d;</i>
					<span>航行航道通告</span>
				</div>
				<div class="toolbar-item" @click="getNoticeList('航道维护尺度')">
					<i class="iconfont">&#xe64a;</i>
					<span>航道维护尺度</span>
				</div>
				<div class="toolbar-item" @click="getTableList('施工船舶')">
					<i class="iconfont"  name="close"></i>
					<span>施工船舶</span>
				</div>
				<div class="toolbar-item" @click="getNoticeList('应急管理')">
					<i class="iconfont">&#xe683;</i>
					<span>应急管理</span>
				</div>
				<div class="toolbar-item" @click="getNoticeList('安全信息预警')">
					<i class="iconfont">&#xe60d;</i>
					<span>安全信息预警</span>
				</div>
				</div>
			</div>
		</van-popup>

		<van-popup v-model="tableShow" position="bottom" :style="{ height: '80%' }">
			<div class="table-container">
				<p style="font-weight: bold; font-size: 1rem; margin-left: 20px; margin-right: 15px; margin-top: 10px;">{{ tableTitle }}</p>
				<el-table :data="tableData" style="width: 100%">
				<el-table-column v-for="col in columnData" :key="col.prop" :prop="col.prop" :label="col.label"></el-table-column>
				</el-table>
			</div>
		</van-popup>
	</div>
</template>
<script>
	import 'ol/ol.css';
	import Map from 'ol/Map';
	import View from 'ol/View';
	import { fromLonLat, transformExtent } from 'ol/proj';
	import ChannelLayer from './map/channel-layer'
	import TiandituLayer from './map/tianditu-layer'
	import MarkerLayer from './map/marker-layer'
	import { layer as alarmLayer, loadAlarms } from './map/alarm-layer'
	import AnchorLayer from './map/anchor-layer'
	import DxfLayer from './map/dxf-layer'
	import CameraLayer from './map/camera-layer'
	import AreaLayer from './map/area-layer'
	import EnclosureLayer from './map/enclosure-layer'
	import { layer as watersiteLayer, loadWatersites, getWaterLevelList } from './map/watersite-layer'
	import { layer as aisLayer, loadShips, selectShip } from './map/ais-layer'
	import { getQueryString } from '@/utils/utils'
	import locatorLayer from './map/locator-layer'
	import ShipDetail from '@/components/Map/ship/ship-detail.vue'
	import {loadTrack, trackLayer, clearTrack} from './map/history-track'
	import WatersitePopup from '@/components/Map/watersite/watersite-popup.vue'
	import { layer as ownerProjectsLayer, loadOwnerProjects, getOwnerProjectDetails } from './map/owner-projects-layer'
	import ProjectDetails from '@/components/Map/ownerprojects/project-detail.vue'
	// import searchMixin from './map/search.js';
	import { 
		sailChannelNoticsList, 
		maintenanceScaleList, 
		safetyWarningList, 
		contingencyManagementList,
		shipList,
		emergencyMisposalList
	} from '@/api/map.js'

	let AisLayer;
	let AlarmLayer;
	let WatersiteLayer;
	let LocatorLayer
	export default {
		name: 'Map',
		components: {
			ShipDetail,
			WatersitePopup,
			ProjectDetails
		},
		// mixins: [searchMixin],
		data() {
			return {
				historyTime: [new Date(), new Date()],
				showStartTimer: false,
				showEndTimer: false,
				map: null,
				center: fromLonLat([107.068, 29.82]),
				centerZoom: 14,
				hiddenContent: false,
				showLayerPopup: false,
				showClearTrack: false,
				selectedFeature: null,
				selectData: {
					hdms: {},
					imgUrls: []
				},
				selectDetail: [],
				showDetail: false,
				showWatersiteDetail: false,
				showShipPopup: false,
				showAlarmPopup: false,
				showWatersitePopup: false,
				visible: {
					channel: true,
					anchor: true,
					marker: true,
					dxf: true,
					ais: true,
					tianditu: true,
					watersite: true,
					alarm: true,
					camera:true,
					area:true,
					locator:true,
					enclosure:true
				},
				urlValue: '',
				isShowDetail: {
					'shipDetail':false,
				},
				shipData: {},
				selectShipId: '',
				shipLongitude: '',
				shipLatitude: '',
				selectedProject: null,
				projectDetailsVisible: false,
				searchOption: {
					value: '',
					loading: false,
					options: []
				},
				showToolbar: false,
				unreadCount: 0, 
				noticeShow: false,
				noticeTitle: '',
				noticeList: [],
				tableShow: false,
				tableTitle: '',
				tableData: [],
				columnData: []

			}
		},
		computed: {
			channelVisible: {
				get: function() {
					return this.visible.channel
				},
				set: function(val) {
					this.visible.channel = val
					ChannelLayer.setVisible(val)
				}
			},
			markerVisible: {
				get: function() {
					return this.visible.marker
				},
				set: function(val) {
					this.visible.marker = val
					MarkerLayer.setVisible(val)
				}
			},
			anchorVisible: {
				get: function() {
					return this.visible.anchor
				},
				set: function(val) {
					this.visible.anchor = val
					AnchorLayer.setVisible(val)
				}
			},
			dxfVisible: {
				get: function() {
					return this.visible.dxf
				},
				set: function(val) {
					this.visible.dxf = val
					DxfLayer.setVisible(val)
				}
			},
			cameraVisible: {
				get: function() {
					return this.visible.camera
				},
				set: function(val) {
					this.visible.camera = val
					CameraLayer.setVisible(val)
				}
			},
			areaVisible: {
				get: function() {
					return this.visible.area
				},
				set: function(val) {
					this.visible.area = val
					AreaLayer.setVisible(val)
				}
			},
			enclosureVisible: {
				get: function() {
					return this.visible.enclosure
				},
				set: function(val) {
					this.visible.enclosure = val
					EnclosureLayer.setVisible(val)
				}
			},
			locatorVisible: {
				get: function() {
					return this.visible.locator
				},
				set: function(val) {
					this.visible.locator = val
					LocatorLayer.setVisible(val)
				}
			},
			tiandituVisible: {
				get: function() {
					return this.visible.tianditu
				},
				set: function(val) {
					this.visible.tianditu = val
					TiandituLayer.setVisible(val)
				}
			},
			aisVisible: {
				get: function() {
					return this.visible.ais
				},
				set: function(val) {
					this.visible.ais = val
					AisLayer.setVisible(val)
				}
			},
			watersiteVisible: {
				get: function() {
					return this.visible.watersite
				},
				set: function(val) {
					this.visible.watersite = val
					WatersiteLayer.setVisible(val)
				}
			},
			alarmVisible: {
				get: function() {
					return this.visible.alarm
				},
				set: function(val) {
					this.visible.alarm = val
					AlarmLayer.setVisible(val)
				}
			},
		},
		created() {
			var component = getQueryString('component')
			//console.log('component'+component)
			if(component != null && component != '') {
				this.component = component
				this.urlValue = component
			}
			var selectShipId = getQueryString('selectShipId')
			//console.log('selectShipId'+selectShipId)
			if(selectShipId != null && selectShipId != '') {
				this.selectShipId = selectShipId
			}
		},
		async mounted() {
			var viewExtent = []
			await this.$request({
				url: 'extent/settings/list',
				method: 'post'
			}).then(res => {
				viewExtent = JSON.parse(res.data[0].extent)
				if(res.data[0].point) {
					this.center = fromLonLat(JSON.parse(res.data[0].point))
				}
			})
			this.map = new Map({
				target: this.$refs.map,
				layers: [
					TiandituLayer,
					ChannelLayer,
					MarkerLayer,
					AnchorLayer,
					DxfLayer,
					CameraLayer,
					AreaLayer,
					EnclosureLayer,
					ownerProjectsLayer()
				],
				controls: [],
				view: new View({
					center: this.center,
					zoom: this.centerZoom,
					maxZoom: 18,
					minZoom: 5,
					projection: "EPSG:3857",
					extent: transformExtent(viewExtent, 'EPSG:4326', 'EPSG:3857'),
				})
			})

			AisLayer = aisLayer(this.map)
			LocatorLayer = locatorLayer(this.map)
			AlarmLayer = alarmLayer(this.map)
			WatersiteLayer = watersiteLayer()
			this.map.addLayer(trackLayer)
			this.map.addLayer(LocatorLayer)
			this.map.addLayer(AisLayer)
			this.map.addLayer(AlarmLayer)
			this.map.addLayer(WatersiteLayer)

			this.map.on('singleclick', this.handleMapClick)

			setInterval(this.getShips, 5000)
			this.getShips()
			this.getWatersites()
			this.getAlarms()
			this.loadProjects()
			var shipLatitude = getQueryString('shipLatitude')
			var shipLongitude = getQueryString('shipLongitude')
			//console.log('shipLongitude'+shipLongitude+'shipLatitude'+shipLatitude)
			if(shipLatitude != null && shipLatitude != '' && shipLongitude != null && shipLongitude != '') {
				this.shipLongitude = shipLongitude
				this.shipLatitude = shipLatitude
				this.setCenter(shipLongitude,)
			}

			console.log('AisLayer:', AisLayer);
			console.log('LocatorLayer:', LocatorLayer);
		},
		methods: {
			updateUnreadCount(count) {
				this.unreadCount = count;
			},
			

			async remoteMethod(query) {
			if (query === '') {
				this.searchOption.options = [];
				return;
			}
			
			this.searchOption.options = [
				{ label: 'AIS', options: [] },
				{ label: '船舶', options: [] },
				{ label: '摄像头', options: [] },
				{ label: '定位器', options: [] },
				{ label: '车辆', options: [] }
			];
			
			// AIS ships
			AisLayer.getSource().getFeatures().forEach(it => {
				var data = it.get('data');
				if (((data.shipName && data.shipName.indexOf(query) !== -1) || (data.mmsi && (data.mmsi + '').indexOf(query) !== -1)) && this.searchOption.options[0].options.length < 5) {
				this.searchOption.options[0].options.push({
					label: data.shipName ? data.shipName + `（${data.mmsi}）` : data.mmsi,
					value: `ship!@#${data.mmsi}`
				});
				}
			});
			
			// Custom ships
			LocatorLayer.getSource().getFeatures().forEach(it => {
				var data = it.get('data');
				if (data.shipName && data.shipName.indexOf(query) !== -1 && this.searchOption.options[1].options.length < 5) {
				this.searchOption.options[1].options.push({
					label: data.shipName,
					value: `ship!@#${data.shipId}`
				});
				}
			});
			
			// Cameras
			const cameraIsOnline = (status) => status !== 1 ? '（不在线）' : '';
			
			CameraLayer.getSource().getFeatures().forEach(it => {
				var data = it.get('data');
				if (data.name.indexOf(query) !== -1 && this.searchOption.options[2].options.length < 5) {
				this.searchOption.options[2].options.push({
					label: data.name + cameraIsOnline(1), // 暂时全部显示在线
					value: `camera!@#${data.id}`
				});
				}
			});
			
			// Locators (assuming they're part of LocatorLayer)
			LocatorLayer.getSource().getFeatures().forEach(it => {
				var data = it.get('data');
				if ((data.deviceName && data.mmsi && data.deviceName.indexOf(query) !== -1) || (data.mmsi && data.mmsi.indexOf(query) !== -1) && this.searchOption.options[3].options.length < 5) {
				this.searchOption.options[3].options.push({
					label: data.deviceName + `（${data.mmsi}）`,
					value: `locator!@#${data.mmsi}`
				});
				}
			});
			
			// Remove empty categories
			this.searchOption.options = this.searchOption.options.filter(group => group.options.length > 0);
			console.log(this.searchOption);
			},

			clearHistoryTrack(){
				clearTrack();
				this.showClearTrack = false;
			},
			queryHistory() {
				if(this.historyTime && this.historyTime.length === 2 && this.historyTime[0] && this.historyTime[1]) {
					let startTime = this.historyTime[0].getTime() / 1000
					let endTime = this.historyTime[1].getTime() / 1000
					this.hiddenContent = true
					clearTrack()
					loadTrack(this.selectData.id, startTime, endTime, ()=> {
						this.showClearTrack = true
					})
				}
			},
			getShips() {
				var extent = transformExtent(this.map.getView().calculateExtent(this.map.getSize()), this.map.getView().getProjection(), "EPSG:4326")
				loadShips(extent)
				if(this.selectShipId != ""){
					var isSelectShip = selectShip(this.selectShipId,this.map.getView());
					if(isSelectShip){
						this.selectShipId = ""
					}
				}
			},
			getWatersites(current) {
				loadWatersites(current)
			},
			getWatersiteDetail() {
				getWaterLevelList(this.selectData, this)
				console.log(this.selectDetail)
				this.showWatersiteDetail = true;
			},
			getAlarms(current) {
				loadAlarms(current)
			},
			setCenter() {
				console.log('setCenter')
				let view = this.map.getView();
				if(this.shipLongitude != '' && this.shipLatitude != ''){
					view.setCenter(fromLonLat([this.shipLongitude, this.shipLatitude]))
					view.setZoom(16)
					this.shipLongitude = ''
					this.shipLatitude = ''
				}else{
					view.setCenter(this.center)
				}
				
				view.setZoom(this.centerZoom)
			},
			setZoom(zoom) {
				let view = this.map.getView();
				view.setZoom(view.getZoom() + zoom)
			},
			closePopup() {
				this.showDetail = false;
				this.showWatersiteDetail = false;
			},
			dateFormat(fmt, date) {
				let ret;
				const opt = {
					"y+": date.getFullYear().toString(), // 年
					"M+": (date.getMonth() + 1).toString(), // 月
					"d+": date.getDate().toString(), // 日
					"H+": date.getHours().toString(), // 时
					"m+": date.getMinutes().toString(), // 分
					"s+": date.getSeconds().toString() // 秒
					// 有其他格式化字符需求可以继续添加，必须转化成字符串
				};
				for(let k in opt) {
					ret = new RegExp("(" + k + ")").exec(fmt);
					if(ret) {
						fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
					}
				}
				return fmt;
			},
			openDetail(type){
				this.isShowDetail[type] = true;
			},
			loadProjects() {
				loadOwnerProjects()
			},
			showProjectDetails(project) {
				this.selectedProject = project;
				this.projectDetailsVisible = true;
				getOwnerProjectDetails(project)
					.then(details => {
						this.selectedProject = { ...this.selectedProject, projectDetails: details };
					})
					.catch(error => {
						console.error('Error fetching project details:', error);
					});
			},
			closeProjectDetails() {
				this.selectedProject = null;
				this.projectDetailsVisible = false;
			},
			handleMapClick(evt) {
				let ret = this.map.forEachFeatureAtPixel(this.map.getEventPixel(evt.originalEvent), function(feature) {
					return feature
				}, {
					hitTolerance: 10
				});
				if(this.selectedFeature) {
					this.selectedFeature.set('selected', false)
					this.selectedFeature = null
				}
				this.showShipPopup = false
				this.showAlarmPopup = false
				this.showWatersitePopup = false
				if(ret) {
					this.selectedFeature = ret;
					this.selectedFeature.set('selected', true)
					var type = this.selectedFeature.get('type')
					//console.log('海图点击事件')
					if(type === 'ship') {
						this.selectData = this.selectedFeature.get('data');
						this.historyTime = [
							new Date(this.dateFormat('yyyy-MM-dd', new Date(this.selectData.postime * 1000 - 86400000)) + ' 00:00:00'),
							new Date(this.dateFormat('yyyy-MM-dd', new Date(this.selectData.postime * 1000)) + ' 23:59:59')
						]
						//console.log(this.selectData)
						this.showShipPopup = true
					} else if(type === 'alarm') {
						this.selectData = this.selectedFeature.get('data');
						//console.log(this.selectData)
						this.showAlarmPopup = true
					} else if(type === 'watersite') {
						this.showWatersitePopup = false
						this.selectData = this.selectedFeature.get('data');
						//console.log(this.selectData)
						this.showWatersitePopup = true
					} else if(type === 'ownerproject') {
						this.showProjectDetails(this.selectedFeature.get('data'));
						console.log(this.selectData)
					}

				} else {
					// Close any open popups if clicking outside
					this.closeProjectDetails();
				}
			},

			shipSearchSelect(value) {
				console.log('shipSearchSelect called with value:', value);
				if (!value) {
				console.log('No value provided, returning');
				return;
				}
				
				const [type, id] = value.split('!@#');
				console.log('Parsed type:', type, 'id:', id);
				
				let feature;
				
				// Reset all popups and selected features
				this.showShipPopup = false;
				this.showAlarmPopup = false;
				this.showWatersitePopup = false;
				if (this.selectedFeature) {
				console.log('Resetting previously selected feature');
				this.selectedFeature.set('selected', false);
				this.selectedFeature = null;
				}
			
				switch(type) {
				case 'ship':
					console.log('Searching for ship feature');
					feature = this.getCurrFeature(id);
					if (feature) {
					console.log('Ship feature found, setting up display');
					this.selectedFeature = feature;
					this.selectedFeature.set('selected', true);
					this.selectData = feature.get('data');
					console.log('Selected ship data:', this.selectData);
					this.historyTime = [
						new Date(this.dateFormat('yyyy-MM-dd', new Date(this.selectData.postime * 1000 - 86400000)) + ' 00:00:00'),
						new Date(this.dateFormat('yyyy-MM-dd', new Date(this.selectData.postime * 1000)) + ' 23:59:59')
					];
					this.showShipPopup = true;
					this.centerOnFeature(feature);
					} else {
					console.log('No ship feature found for id:', id);
					}
				break;
				case 'camera':
					feature = this.getCameraFeature(id);
					if (feature) {
					this.selectedFeature = feature;
					this.selectedFeature.set('selected', true);
					this.selectData = feature.get('data');
					// Implement camera-specific logic here if needed
					this.centerOnFeature(feature);
					}
					break;
				case 'locator':
					feature = this.getLocatorFeature(id);
					if (feature) {
					this.selectedFeature = feature;
					this.selectedFeature.set('selected', true);
					this.selectData = feature.get('data');
					this.showShipPopup = true; // Assuming locators use the same popup as ships
					this.centerOnFeature(feature);
					}
					break;
				case 'ownerproject':
					feature = this.getOwnerProjectFeature(id);
					if (feature) {
					this.selectedFeature = feature;
					this.selectedFeature.set('selected', true);
					this.showProjectDetails(feature.get('data'));
					this.centerOnFeature(feature);
					}
					break;
				default:
					console.log('Unknown type:', type);
				}
			},

			getCurrFeature(value) {
				console.log('getCurrFeature called with value:', value);
				const layers = [AisLayer, LocatorLayer];
				for (let layer of layers) {
				console.log('Checking layer:', layer);
				if (layer && layer.getSource()) {
					console.log('Layer source found');
					const features = layer.getSource().getFeatures();
					console.log('Number of features in layer:', features.length);
					let feature = features.find(it => {
					const data = it.get('data');
					console.log('Checking feature data:', data);
					return (data.mmsi + '' === value + '') || (data.shipId + '' === value + '');
					});
					if (feature) {
					console.log('Feature found:', feature);
					return feature;
					}
				} else {
					console.log('Layer or layer source is undefined');
				}
				}
				console.log('No matching feature found');
				return null;
			},

			getCameraFeature(id) {
				return CameraLayer && CameraLayer.getSource()
				? CameraLayer.getSource().getFeatures().find(feature => feature.get('data').id === id)
				: null;
			},

			getLocatorFeature(id) {
				return LocatorLayer && LocatorLayer.getSource()
				? LocatorLayer.getSource().getFeatures().find(feature => feature.get('data').mmsi === id)
				: null;
			},

			getOwnerProjectFeature(id) {
				return this.ownerProjectsLayer && this.ownerProjectsLayer.getSource()
				? this.ownerProjectsLayer.getSource().getFeatures().find(feature => feature.get('data').id === id)
				: null;
			},

			centerOnFeature(feature) {
				if (feature && feature.getGeometry()) {
				this.map.getView().animate({
					center: feature.getGeometry().getCoordinates(),
					zoom: 14
				});
				}
			},

			getNoticeList(title) {
				this.noticeTitle = title
				this.noticeList = []
				let apiCall

				switch (title) {
					case '航行航道通告':
					apiCall = sailChannelNoticsList
					break
					case '航道维护尺度':
					apiCall = maintenanceScaleList
					break
					case '安全信息预警':
					apiCall = safetyWarningList
					break
					case '应急管理':
					apiCall = contingencyManagementList
					break
					default:
					console.error('Unknown notice type:', title)
					return
				}

				apiCall({ current: 1, size: 10 }).then((response) => {
					const { data } = response
					this.noticeList = data || []
					if (title === '应急管理' && this.noticeList.length === 0) {
					this.noticeList.push({ title: '应急处置' })
					}
					this.noticeShow = true
				}).catch(error => {
					console.error('Error fetching notices:', error)
				})
			},

			getTableList(title) {
				this.tableTitle = title
				let apiCall, columns

				if (title === '施工船舶') {
					apiCall = shipList
					columns = [
					{ prop: 'name', label: '船舶名称' },
					{ prop: 'typeName', label: '船舶类型' },
					{ prop: 'shipLen', label: '船长' },
					{ prop: 'profileWidth', label: '型宽' },
					{ prop: 'fullDraft', label: '满载吃水' },
					{ prop: 'grossTonnage', label: '总吨位' },
					{ prop: 'contacts', label: '联系人' },
					{ prop: 'contactInfo', label: '联系方式' }
					]
				} else {
					apiCall = emergencyMisposalList
					columns = [
					{ prop: 'title', label: '名称' },
					{ prop: 'content', label: '内容' },
					{ prop: 'happenDate', label: '发生日期' },
					{ prop: 'constructionSite', label: '施工地点' },
					{ prop: 'issueMatters', label: '问题事项' },
					{ prop: 'emergencyMeasure', label: '应急措施' },
					{ prop: 'fillingPersonnel', label: '填报人员' }
					]
				}

				apiCall({ current: 1, size: 999999 }).then((response) => {
					const { data } = response
					this.tableData = data
					this.columnData = columns
					this.tableShow = true
				}).catch(error => {
					console.error('Error fetching table data:', error)
				})
			},

	}
}
</script>
<style scoped>
	.van-popup--bottom {
		bottom: 80px;
		max-height: 80%;
	}

	.van-popup--bottom-full {
		bottom: 80px;
		max-height: 80%;
	}

	.project-details-popup {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1000;
	}

	.ship-search {
		position: absolute;
		width: 40%;
		top: 1rem;
        left: 0.5rem;
		z-index: 100;
	}
	.ship-search .el-select {
		width: 100%;
	}
	
	.ship-search .el-input__inner {
		width: 100%;
		height: 40px; /* Adjust this value as needed */
		line-height: 40px; /* Should match the height */
		font-size: 14px; /* Adjust as needed */
	}
	.toolbar-container {
		padding: 16px;
	}

	.toolbar-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;
	}

	.toolbar-content {
		display: flex;
		flex-wrap: wrap;
	}

	.toolbar-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 25%;
		margin-bottom: 16px;
	}

	.toolbar-item i {
		font-size: 24px;
		margin-bottom: 8px;
	}


</style>
<style>
	.el-table td.el-table__cell {
		border: none;
	}

	.el-table .cell {
		line-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.el-table th.el-table__cell>.cell {
		padding-left: 5px;
		padding-right: 5px;
	}

	.bottom-full {
		bottom: 0px!important;
		max-height: 80%;
	}

	.map-container #map {
		width: 100%;
		height: 100%;
	}

	.map-container {
		position: relative;
	}

	.toolbox {
		position: fixed;
		background: #fff;
		color: #333;
		z-index: 100;
		border-radius: 0.2rem;
		width: 1rem;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.5rem;
		text-align: center;
		font-family: iconfont;
	}

	.toolbox.toolbox-layer {
		top: 1rem;
		right: 0.5rem;
	}

	.toolbox.toolbox-clear {
		top: 2.5rem;
		right: 0.5rem;
	}

	.toolbox.toolbox-zoom-in {
		right: 0.5rem;
		bottom: 4rem;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.toolbox.toolbox-zoom-out {
		right: 0.5rem;
		bottom: 3rem;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-top: 1px solid #ccc;
	}

	.toolbox.toolbox-center {
		left: 0.5rem;
		bottom: 3rem;
		color: #3385ff;
	}

	.toolbox.toolbar-toggle {
		bottom: 4.7rem;
		left: 0.5rem;
		color: #3385ff;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		cursor: pointer;
		z-index: 100;
		font-size: 14px;
		height: 1rem; /* Increased height to accommodate both icon and text */
	}


	.layer-popup-title {
		background: #F2F5F8;
		color: #8f969f;
		height: .780488rem;
		line-height: .780488rem;
		padding-left: .317073rem;
	}

	.layer-switch li {
		height: 1rem;
		line-height: 1rem;
	}

	.layer-switch li label {
		display: inline-block;
		width: 1.2rem;
		margin-left: 0.2rem;
	}

	.layer-switch li span {
		font-family: iconfont;
		color: #2770d7;
		font-weight: 700;
		font-size: 0.5rem;
		height: 1rem;
		line-height: 1rem;
		padding-left: 0.2rem;
	}

	.detail-header {
		padding: 20px 20px 0px 20px;
		height: 30px;
	}

	.detail-header .header-name {
		width: 40%;
		font-size: 20px;
		color: #45474f;
		float: left;
		margin-right: 10px;
		display: block; 
		padding: 10px;
		margin-bottom: 5px; 
		/*font-weight: 700;*/
	}

	.detail-header .more {
		float: right;
		font-size: 12px;
		color: #969799;
		margin-top: 1px;
		padding: 3px 8px 2px 8px;
	}

	.detail-header .position {
		margin-left: 10px;
		margin-top: 1px;
		font-size: 10px;
		background-color: #80D3F8;
		color: white;
		padding: 3px 5px 3px 5px;
		border-radius: 6px;
		float: left;
	}

	.detail-header .ship-type {
		margin-left: 10px;
		margin-top: 1px;
		font-size: 10px;
		background-color: #FF0000;
		color: white;
		padding: 2px 8px 2px 8px;
		border-radius: 6px;
		float: left;
	}

	.detail-header .ship-personnel {
		margin-left: 10px;
		margin-top: 1px;
		font-size: 10px;
		background-color: #FF0000;
		color: white;
		padding: 3px 6px 3px 6px;
		border-radius: 50%;
		float: left;
	}

	.detail-header .ship-personnel-date {
		float: right;
		font-size: 12px;
		margin-top: 1px;
		color: #969799;
		margin-top: 8px;
	}

	.header-content {
		font-size: 20px;
		color: #45474f;
		padding: 10px; 
		display: block; 
		margin-bottom: 5px;
		/* font-weight: 700; */
	}

	.header-ms {
		font-size: 20px;
		color: #8f959f;
		padding: 10px; 
		display: block; 
		margin-bottom: 5px;
	}

	.detail-locate-block {
		padding: 0.2rem 1rem;
	}

	.detail-list {
		width: 9.41463rem;
		margin: 0 auto;
	}

	.detail-list .list-item {
		height: 1.195122rem;
		line-height: 1.195122rem;
		border-bottom: .012195rem solid #eee;
	}

	.ship-detail-list .ship-list-item,
	.ship-detail-list2 .ship-list-item {
		height: 0.7rem;
		line-height: 0.7rem;
	}

	.ship-detail-list {
		padding: 20px 0px 20px 0px;
		width: 9.41463rem;
		border-bottom: .012195rem solid #eee;
	}

	.ship-detail-list2 {
		padding: 15px 0px 15px 0px;
		width: 9.41463rem;
	}

	.ship-detail-list .list-item,
	.ship-detail-list2 .list-item {
		height: 1.195122rem;
		line-height: 1.195122rem;
		border-bottom: .012195rem solid #eee;
	}

	.list-item .list-label {
		float: left;
		color: #9296a0;
	}

	.list-item .list-value {
		float: right;
		color: #454850;
	}

	.ship-list-item .ship-item {
		width: 50%;
		float: left;
	}

	.ship-list-item .ship-item2 {
		margin-left: 6%;
		width: 60%;
		float: left;
	}

	.ship-list-item .ship-item3 {
		width: 34%;
		float: left;
	}

	.ship-list-item .ship-item4 {
		margin-left: 6%;
		width: 46%;
		float: left;
	}

	.ship-list-item .ship-item5 {
		width: 35%;
		margin-left: 4%;
		float: left;
	}

	.ship-list-item .ship-item .item-label,
	.ship-list-item .ship-item2 .item-label,
	.ship-list-item .ship-item3 .item-label,
	.ship-list-item .ship-item4 .item-label,
	.ship-list-item .ship-item5 .item-label {
		float: left;
		color: #9296a0;
	}

	.ship-list-item .ship-item .item-value,
	.ship-list-item .ship-item2 .item-value {
		float: left;
		text-align: left;
	}

	.ship-list-item .ship-item .item-value2 {
		float: left;
		text-align: left;
		color: #454850;
	}

	.detail-page {
		width: 100%;
		/* height: 100%; */
		/* overflow: hidden; */
		z-index: 1000;
		position: fixed;
		overflow: scroll!important;
	}
</style>
