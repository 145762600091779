<template>
	<div class="container">
		<van-nav-bar :style="urlValue == '' ? '':{'margin-top':'30px'}" :left-arrow="''" @click-left="closeDetail">
			<div v-if="userRiverCollection.indexOf(watersiteData.waterSite) == -1" class="icon uncollection" slot="right" @click="saveCollectionData"></div>
			<div v-else class="icon collection" slot="right" @click="saveCollectionData"></div>
			<div class="icon tool" slot="right"></div>
		</van-nav-bar>
		<van-swipe class="my-swipe" indicator-color="white" @change="swipChange">
			<van-swipe-item v-for="(item, index) in userRiverCollectionList" :key="index">
				<div class="container-content" :style="urlValue == '' ? {'overflow-y':'auto','height':'100%'}:{'overflow-y':'auto','height':'calc(100% - 80px)'}">
					<div class="detail-header-box">
						<div class="header-name">{{watersiteData.waterSite}} <img :src="require('@/assets/images/map/watersite/position.png')"></div>
						<div class="header-value">{{currentdayWatersite}} <span>m</span></div>
						<div class="header-time">{{currentday}}</div>
					</div>

					<div class="detail-tabbar-box">
						<van-tabbar v-model="tabbarActive" @change="initUpperReachesChart">
							<van-tabbar-item>水位</van-tabbar-item>
							<!--<van-tabbar-item>温度</van-tabbar-item>
							<van-tabbar-item>降水量</van-tabbar-item>
							<van-tabbar-item>风力</van-tabbar-item>-->
						</van-tabbar>
						<div v-if="tabbarActive == 0" class="tabbar-content waterlevel" :id="'waterlevel-echart-box-'+index">
						</div>
						<!--<div class="tabbar-content temperature">
				</div>-->
					</div>

					<div class="detail-history-box">
						<div class="history-box">
							<div class="history-header-box">
								<div class="header-name">历史水位</div>
								<div class="header-tool"><img :src="require('@/assets/images/map/watersite/switch.png')" @click="showHistory"></div>
							</div>
							<div class="history-echart-box" :id="'history-echart-box-'+index">
							</div>
						</div>
					</div>
				</div>
			</van-swipe-item>
		</van-swipe>
	</div>
</template>
<script>
	import { getHistoryData, getUserRiverCollectionData, saveUserRiverCollectionData, getUpperReachesData } from '@/views/index/map/watersite-layer'
	import { getQueryString, getNowTime } from '@/utils/utils'
	import * as echarts from 'echarts';
	var _this
	export default {
		name: 'WatersiteDetail',
		props: ['selectData', 'pSiteNameList', 'pLevelList', 'pCurrentdayWatersite'],
		data() {
			return {
				urlValue: '',
				returnText: "",
				currentday: getNowTime(),
				watersiteData: {},
				tabbarActive: 0,
				swipIndex: 0,
				showWatersiteHistory: false,
				historyYearList: [],
				historyLevelList: [],
				userRiverCollection: '',
				userRiverCollectionList: [],
				upperReachesOption: {
					grid: {
						x: "0%", //x 偏移量
						y: "25%", // y 偏移量
						width: "100%", // 宽度
						height: "70%" // 高度
					},
					xAxis: {
						type: 'category',
						position: 'top',
						data: [],
						splitLine: {
							show: false
						},
						axisLine: { //x轴坐标轴
							show: false
						},
						axisLabel: {
							show: true,
							color: '#FFFFFF'
						},
						axisTick: { //x轴刻度线
							show: false,
							alignWithLabel: false,
						}

					},
					yAxis: {
						type: 'value',
						max: 300,
						splitLine: {
							show: false
						},
						axisLine: { //x轴坐标轴
							show: false
						},
						axisLabel: {
							show: false
						},
						axisTick: { //x轴刻度线
							show: false,
							alignWithLabel: false,
						}
					},
					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true,
									color: '#FFFFFF'
								},
								color: '#FFFFFF'
							}
						},
						data: [],
						type: 'line'
					}]
				},
				historyOption: {
					grid: {
						x: "5%", //x 偏移量
						y: "25%", // y 偏移量
						width: "85%", // 宽度
						height: "60%" // 高度
					},
					xAxis: {
						type: 'category',
						position: 'top',
						data: [],
						splitLine: {
							show: false
						},
						axisLine: { //x轴坐标轴
							show: false
						},
						axisLabel: {
							show: true,
							color: '#FFFFFF'
						},
						axisTick: { //x轴刻度线
							show: false,
							alignWithLabel: false,
						}

					},
					yAxis: {
						type: 'value',
						max: 300,
						splitLine: {
							show: false
						},
						axisLine: { //x轴坐标轴
							show: false
						},
						axisLabel: {
							show: false
						},
						axisTick: { //x轴刻度线
							show: false,
							alignWithLabel: false,
						}
					},
					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true,
									color: '#FFFFFF'
								},
								color: '#FFFFFF'
							}
						},
						data: [],
						type: 'line',
						markLine: {
							silent: true,
							lineStyle: {
								color: '#009DFF'
							},
							label: {
								color: '#FFFFFF'
							},
							data: [{
									yAxis: 180
								},
								{
									yAxis: 100
								}
							]
						}
					}]
				},
			}
		},
		created() {
			_this = this
			console.log(this.urlValue)
			var value = getQueryString('component')
			if(value != null && value != '') {
				this.urlValue = value
			}
		},
		mounted() {
			this.watersiteData = this.selectData
			this.siteNameList = this.pSiteNameList
			this.levelList = this.pLevelList
			this.currentdayWatersite = this.pCurrentdayWatersite
			this.getInitData()
		},
		methods: {
			async getInitData() {
				await getUserRiverCollectionData(this.watersiteData, this) //获取收藏
				this.initUpperReachesChart();
				getHistoryData(this.watersiteData, this); //获取历史水位
			},
			saveCollectionData() {
				saveUserRiverCollectionData(this.watersiteData, this)
			},
			initUpperReachesChart() {
				if(this.tabbarActive == 0) {
					//setTimeout(function() {
						console.log(_this.siteNameList)
						_this.upperReachesOption.xAxis.data = _this.siteNameList
						_this.upperReachesOption.series[0].data = _this.levelList
						let waterlevelEchartBox = document.getElementById('waterlevel-echart-box-' + _this.swipIndex)
						echarts.init(waterlevelEchartBox).setOption(_this.upperReachesOption)
					//}, 200);
				}
			},
			initHistoryChart() {
				//setTimeout(function() {
				_this.historyOption.xAxis.data = _this.historyYearList
				_this.historyOption.series[0].data = _this.historyLevelList
				let historyEchartBox = document.getElementById('history-echart-box-' + _this.swipIndex)
				echarts.init(historyEchartBox).setOption(_this.historyOption)
				//}, 200);
			},
			swipChange(index) {
				console.log('swipChange')
				this.swipIndex = index
				var watersite = this.userRiverCollectionList[index]
				this.watersiteData = {
					"waterSite": watersite
				}
				getUpperReachesData(this.watersiteData, this) //获取上游
				getHistoryData(this.watersiteData, this); //获取历史水位
			},
			closeDetail() {
				this.$emit('closeDetail', 'detail');
			},
			showHistory() {
				this.$emit('showHistory');
			}
		}
	}
</script>
<style scoped>
	[class*=van-hairline]::after {
		border: 0 solid #009DFF
	}
	
	.van-nav-bar {
		background-color: #009DFF;
	}
	
	.van-tabbar--fixed {
		margin-top: 20px;
		position: inherit;
	}
	
	.van-tabbar {
		background-color: transparent;
		height: 35px;
		width: calc(100% - 20px);
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.van-tabbar-item {
		color: rgb(255 255 255 / 0.7);
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.van-tabbar-item--active {
		border-radius: 50px;
		color: #FFFFFF;
		background-color: #1F80BC;
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.container {
		background-color: #009DFF;
	}
	
	.container-content {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.icon {
		width: 10px;
		height: 10px;
		background-size: cover;
		background-position: center;
		display: inline-block;
		float: left;
	}
	
	.tool {
		background: url("~@/assets/images/map/watersite/tool.png") no-repeat;
		width: 30px;
		padding-top: 20px;
		background-size: 80% 80%;
		margin-top: 2px;
	}
	
	.uncollection {
		background: url("~@/assets/images/map/watersite/uncollection.png") no-repeat;
		width: 40px;
		padding-top: 26px;
		/* background-size: 85% 70%; */
		margin-top: -10px;
	}
	
	.collection {
		background: url("~@/assets/images/map/watersite/collection.png") no-repeat;
		width: 40px;
		padding-top: 26px;
		/* background-size: 85% 70%; */
		margin-top: -10px;
	}
	
	.detail-header-box .header-name {
		height: 40px;
		line-height: 30px;
		text-align: center;
		color: #FFFFFF;
		/*width: calc(100% - 10px);*/
		width: 100%;
		font-size: 20px;
	}
	
	.detail-header-box .header-name img {
		width: 15px;
		height: 15px;
	}
	
	.detail-header-box .header-value {
		height: 90px;
		text-align: center;
		color: #FFFFFF;
		/*width: calc(100% - 10px);*/
		width: 100%;
		font-size: 70px;
	}
	
	.detail-header-box .header-time {
		height: 40px;
		line-height: 30px;
		text-align: center;
		color: #FFFFFF;
		/*width: calc(100% - 10px);*/
		width: 100%;
	}
	
	.tabbar-content {
		width: 100%;
		height: 150px;
		margin-top: 10px;
	}
	
	.detail-history-box {
		width: 100%;
		height: 300px;
	}
	
	.history-box {
		width: 100%;
		height: 250px;
		background-color: rgb(255 255 255 / 0.4);
		border-radius: 10px;
		margin-top: 20px;
	}
	
	.history-header-box {
		width: calc(100% - 20px);
		height: 20px;
		line-height: 20px;
		padding: 10px;
	}
	
	.history-header-box .header-name {
		color: #FFFFFF;
		float: left;
	}
	
	.history-header-box .header-tool {
		float: right;
	}
	
	.history-header-box .header-tool img {
		width: 30px;
		height: 30px;
		margin-top: -5px;
	}
	
	.history-echart-box {
		margin-top: -20px;
		width: 100%;
		height: 220px;
	}
</style>
<style lass="scss" scoped>
	/deep/ .van-icon {
		color: #FFFFFF;
	}
</style>