import { markerList } from "@/api/map.js";
import { Vector as VectorSource} from 'ol/source'
import { Vector as VectorLayer} from 'ol/layer'
import { Style, Icon,  Text } from 'ol/style'
import { fromLonLat } from 'ol/proj'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
const source = new VectorSource();
markerList().then(res => {
    const {data} = res
    let features = [];
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let feature = new Feature({
            geometry: new Point(fromLonLat([item.longitude, item.latitude]))
        });
        feature.setStyle(new  Style({
            image: new  Icon({
                src: 'https://www.cjienc.com' + item.img
            }),
            text: new  Text({
                text: item.name,
                textBaseline: 'top',
                offsetY: 18
            })
        }))
        features.push(feature);
    }
    source.addFeatures(features);
})
export default new VectorLayer({
    source: source,
    declutter: true,
    maxResolution: 150
})