<template>
	<div class="container">
		<van-nav-bar :style="urlValue == '' ? '':{'margin-top':'30px'}" title="水位信息" :left-arrow="''" @click-left="closeDetail">
		</van-nav-bar>
		<div class="container-content" :style="urlValue == '' ? {'overflow-y':'auto','height':'100%'}:{'overflow-y':'auto','height':'calc(100% - 80px)'}">
			<div class="detail-header-box">
				<van-row type="flex" justify="center">
					<van-col span="8">
						<van-button class="btn" @click="showPicker = true">{{watersiteData.waterSite}}</van-button>
					</van-col>
					<van-col span="1">
					</van-col>
					<van-col span="14">
						<van-button class="btn" @click="showCalendar = true">{{selectDateStr[0]}} 至 {{selectDateStr[1]}}</van-button>
					</van-col>
				</van-row>

			</div>

			<div class="detail-history-box">
				<div class="header-name">单位：米</div>
				<div class="history-echart-box" id="history-echart-box1">
				</div>
				<div class="history-echart-box" id="history-echart-box2">
				</div>
			</div>
		</div>
		<van-calendar v-model="showCalendar" :default-date="defaultDate" :min-date="minDate" type="range" @confirm="calendarConfirm" />
		<van-popup v-model="showPicker" position="bottom">
			<van-picker show-toolbar :columns="waterSitesList" :default-index="defaultIndex" @cancel="showPicker = false" @confirm="pickerConfirm" />
		</van-popup>
	</div>
</template>
<script>
	import { getRiverLevel, getWaterSites } from "@/api/watersite.js";
	import { getQueryString, formatDate } from '@/utils/utils'
	import * as echarts from 'echarts';
	var _this
	export default {
		name: 'WatersiteDetail',
		props: ['selectData', 'pSiteNameList', 'pLevelList', 'pCurrentdayWatersite'],
		data() {
			return {
				urlValue: '',
				returnText: "",
				minDate: new Date(2008, 0, 1),
				defaultDate: [],
				defaultIndex: 0,
				selectDateStr: [],
				waterSitesList: [],
				watersiteData: {},
				tabbarActive: 0,
				swipIndex: 0,
				showWatersiteHistory: false,
				showCalendar: false,
				showPicker: false,
				historyYearList: [],
				historyLevelList: [],
				userRiverCollection: '',
				userRiverCollectionList: [],
				historyData1: {},
				historyData2: {},
				historyOption: {
					grid: {
						x: "10%", //x 偏移量
						y: "45px", // y 偏移量
						width: "85%", // 宽度
						height: "190px" // 高度
					},
					legend: {
						data: []
					},
					xAxis: {
						type: 'category',
						data: [],
					},
					yAxis: {
						type: 'value',
					},
					series: [{
						data: [],
						type: 'line'
					}]
				},
			}
		},
		created() {
			_this = this
			console.log(this.urlValue)
			var value = getQueryString('component')
			if(value != null && value != '') {
				this.urlValue = value
			}

		},
		mounted() {
			this.watersiteData = this.selectData
			var date = new Date()
			date.setDate(date.getDate() - 6)
			this.defaultDate.push(date)
			this.selectDateStr.push(formatDate(date, 'yyyy-MM-dd'));
			this.defaultDate.push(new Date());
			this.selectDateStr.push(formatDate(new Date(), 'yyyy-MM-dd'));
			this.getWaterSitesList();
			this.getInitData();
		},
		methods: {
			async pickerConfirm(value, index) {
				console.log(index)
				this.showPicker = false
				this.watersiteData.waterSite = value
				await this.getInitData()
			},
			async calendarConfirm(date) {
				this.showCalendar = false;
				this.selectDateStr = []
				this.selectDateStr.push(formatDate(date[0], 'yyyy-MM-dd'));
				this.selectDateStr.push(formatDate(date[1], 'yyyy-MM-dd'));
				this.defaultDate = date;
				await this.getInitData()
			},
			async getWaterSitesList() {
				console.log('getWaterSites')
				var resData = await getWaterSites(false)
				for(var i in resData.data) {
					var siteName = resData.data[i].realName
					siteName = siteName == "涪陵：清溪场" ? "涪陵" : siteName == "秭归（郭家坝）" ? "秭归" : siteName
					if(siteName == _this.watersiteData.waterSite) {
						_this.defaultIndex = i
					}
					_this.waterSitesList.push(siteName);
				}
			},
			async getInitData() {
				var yearStr = _this.selectDateStr[0].split("-")[0]
				var historyData1 = {
					legendData: [],
					categories: [],
					series: []
				}
				var historyData2 = {
					legendData: [],
					categories: [],
					series: []
				}
				var date = this.calculationDate(_this.selectDateStr[0], _this.selectDateStr[1])
				var regExp = /-/g;
				for(var i = date; i >= 0; i--) {
					var endDate = new Date(_this.selectDateStr[1].replaceAll(regExp, "/"))
					var time = endDate.setDate(endDate.getDate() - i);
					historyData1.categories.push(formatDate(time, 'MM-dd'))
					historyData2.categories.push(formatDate(time, 'MM-dd'))
				}
				for(var x = 0; x < 3; x++) {
					var y = (yearStr - x)
					historyData1.legendData.push(y + "")
					var startTime = _this.selectDateStr[0].replace(yearStr, y + "") + " 00:00:00"
					var endTime = _this.selectDateStr[1].replace(yearStr, y + "") + " 23:59:59"
					console.log('getInitData')
					var resData = await getRiverLevel(startTime.replaceAll(regExp, "/"), endTime.replaceAll(regExp, "/"), _this.watersiteData.waterSite)
					resData = resData.data.data.rows.length > 0 ? resData.data.data.rows : []
					var seriesData1 = {
						name: y + "",
						type: 'line',
						itemStyle: {
							normal: {
								label: {
									show: true
								}
							}
						},
					}
					var dataValue1 = [];
					for(var cd of historyData1.categories) {
						var value = 0
						if(resData.length > 0) {
							for(var rd of resData) {
								var timeStr = formatDate(new Date(rd.time), 'MM-dd')
								if(cd == timeStr) {
									value = rd.level
									break;
								}
							}
						}
						dataValue1.push(value);
						seriesData1['data'] = dataValue1
					}
					historyData1.series.push(seriesData1)
				}
				historyData1.series.reverse()
				_this.historyData1 = JSON.parse(JSON.stringify(historyData1))
				var dataValue2 = [];
				for(var m = 0; m < 2; m++) {
					var name = historyData1.series[m].name + '与' + historyData1.series[m + 1].name + '水位对比'
					historyData2.legendData.push(name)
					var seriesData2 = {
						name: name,
						type: 'line',
						itemStyle: {
							normal: {
								label: {
									show: true
								}
							}
						},
					}
					var data1 = historyData1.series[m].data
					var data2 = historyData1.series[m + 1].data
					for(var d = 0; d < data1.length; d++) {
						dataValue2.push((data1[d] - data2[d]).toFixed(2))
					}
					seriesData2['data'] = dataValue2
					historyData2.series.push(seriesData2)
				}
				_this.historyData2 = JSON.parse(JSON.stringify(historyData2))
				this.initHistoryChart()
			},
			calculationDate(startDateStr, endDateStr) {
				var endDate = new Date(endDateStr)
				var startDate = new Date(startDateStr)
				return(endDate - startDate) / (1000 * 3600 * 24)
			},
			initHistoryChart() {
				var historyOption1 = JSON.parse(JSON.stringify(this.historyOption))
				var historyOption2 = JSON.parse(JSON.stringify(this.historyOption))
				var dataZoom = [{
					type: 'inside',
					start: 0,
					end: 20
				}, {
					start: 0,
					end: 20
				}]
				var gridHeight = '150px'
				var addDataZoom = false
				if(_this.historyData1.categories.length > 7) {
					addDataZoom = true
				}
				if(addDataZoom) {
					historyOption1.dataZoom = dataZoom
					historyOption1.grid.height = gridHeight
					historyOption2.dataZoom = dataZoom
					historyOption2.grid.height = gridHeight
				}
				historyOption1.legend.data = _this.historyData1.legendData
				//console.log(JSON.stringify(historyOption1.legend.data))
				historyOption1.xAxis.data = _this.historyData1.categories
				//console.log(JSON.stringify(historyOption1.xAxis))
				historyOption1.series = _this.historyData1.series
				//console.log(JSON.stringify(historyOption1.series))
				historyOption1.yAxis.max = 300
				let historyEchartBox1 = document.getElementById('history-echart-box1')
				echarts.init(historyEchartBox1).setOption(historyOption1)

				historyOption2.legend.data = _this.historyData2.legendData
				//console.log(JSON.stringify(historyOption2.legend.data))
				historyOption2.xAxis.data = _this.historyData2.categories
				//console.log(JSON.stringify(historyOption2.xAxis))
				historyOption2.series = _this.historyData2.series
				//console.log(JSON.stringify(historyOption2.series))
				let historyEchartBox2 = document.getElementById('history-echart-box2')
				echarts.init(historyEchartBox2).setOption(historyOption2)
			},
			closeDetail() {
				this.$emit('closeDetail', 'history');
			},
		}
	}
</script>
<style scoped>
	[class*=van-hairline]::after {
		border: 0 solid #009DFF
	}
	
	.van-nav-bar {
		background-color: #009DFF;
	}
	
	.van-tabbar--fixed {
		margin-top: 20px;
		position: inherit;
	}
	
	.van-tabbar {
		background-color: transparent;
		height: 35px;
		width: calc(100% - 20px);
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.van-tabbar-item {
		color: rgb(255 255 255 / 0.7);
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.van-tabbar-item--active {
		border-radius: 50px;
		color: #FFFFFF;
		background-color: #1F80BC;
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.container {
		background-color: #ECECEC;
	}
	
	.detail-header-box {
		text-align: center;
		color: #FFFFFF;
		/*width: calc(100% - 10px);*/
		width: 100%;
		font-size: 20px;
		margin-top: 10px;
	}
	
	.detail-header-box .btn {
		text-align: center;
		color: #009DFF;
		width: 100%;
		border-radius: 10px;
		border-color: #009DFF;
	}
	
	.container-content {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.detail-history-box {
		width: 100%;
		height: 600px;
		background-color: rgb(255 255 255 / 0.6);
		border-radius: 10px;
		margin-top: 20px;
	}
	
	.detail-history-box .header-name {
		height: 35px;
		line-height: 35px;
		margin-left: 10px;
		font-size: 1rem;
	}
	
	.history-echart-box {
		width: 100%;
		height: 270px;
		margin-bottom: 20px;
		/*background-color: red;*/
	}
</style>
<style lass="scss" scoped>
	/deep/ .van-icon {
		color: #FFFFFF;
	}
	
	/deep/ .van-nav-bar__title {
		color: #FFFFFF;
	}
</style>